import React, { useState } from "react";
import "../GlobalStyles.css";
import "./EnterCoupon.css";
import CouponsService from "../../services/CouponsService";
import UserService from "../../services/UserService";
import { useNavigate } from "react-router-dom";
import {useAccount} from "../../context/AccountContext";

function EnterCoupon() {
  const navigate = useNavigate();
  const account = useAccount();
  const [coupon, setCoupon] = useState("");
  const [error, setError] = useState(false);
  const [inputError, setInputError] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);

  const handleChange = (e) => {
    e.preventDefault();
    setInputError(false);
    //setDisabledButton(e.target.value.length !== 16);
    setDisabledButton(e.target.value.replace(/\s/g, "").length === 0);
    setCoupon(e.target.value);
    setError(false);
  };

  const onValidateCoupon = (e) => {
      e.preventDefault();
    setDisabledButton(true);
    CouponsService.checkCouponValidity(coupon)
      .then((response) => {
        setError(false);
        sessionStorage.setItem("discount-coupon", coupon);
        if (UserService.isLoggedIn()) {
          navigate("/PickProgram");
        } else {
            // console.debug("Retrying authorization")
            try{
                UserService.doLogin(process.env.REACT_APP_ENTER_COUPON_REDIRECT_URI,account.language);
            }catch (e){
                // console.debug("Error slow connection")
            }
        }
      })
      .catch((error) => {
        setError(true);
      })
      .finally(() => {
        setDisabledButton(false);
      });
  };

  return (
    <div id="enter-coupon">
      <div id="enter-coupon-wrapper">
          <form autoComplete="off" onSubmit={onValidateCoupon} className={"coupon-wrapper"} >
            <span id={"enter-code-button"}>Έχεις κωδικό προσφοράς;</span>
              <div className={"coupon-inner-wrapper"}>
                  <div className={`coupon-textbox-wrapper ${error || inputError ? "coupon-error" : ""}`}>
                      <div className={"coupon-input"}>
                          <input type={"text"} placeholder={"Πληκτρολόγησε τον κωδικό εδώ"} onChange={handleChange} value={coupon}/>
                          {/*<PriorityHighIcon id="error-input-icon" className={"coupon-input-error"} />*/}
                      </div>
                      <button type={"submit"}
                          disabled={disabledButton}
                      >
                          <div className={"coupon-textbox-button"}></div>
                      </button>
                  </div>
                  {(error || inputError) &&
                      <span>Ο κωδικός δεν είναι έγκυρος</span>
                  }
              </div>
          </form>
      </div>
    </div>
  );
}

export default EnterCoupon;
