import axios from "axios";

import {
  LandingPageHelper,
  PickProgramPageHelper,
  ProgramBannersHelper,
  QuestionnaireHelper
} from "../components/Utilities/CmsHelpers";

const api = process
  .env
  .REACT_APP_ANT1_CMS_BASE_URL
  .concat(process.env.REACT_APP_ANT1_CMS_API_PATH);

const CmsService = {

  getContent: (page) => {
    return axios
      .get(api.concat(page))
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error.response);
      });
  },

  getLandingPageContent: () => {
    return CmsService.getContent(LandingPageHelper.PAGE_URL);
  },

  getPickProgramPageContent: () => {
    return CmsService.getContent(PickProgramPageHelper.PAGE_URL);
  },

  getProgramBanners: () => {
    return CmsService.getContent(ProgramBannersHelper.PAGE_URL);
  },
  getQuestionnaires: () => {
    return CmsService.getContent(QuestionnaireHelper.PAGE_URL);
  },
};

export default CmsService;
