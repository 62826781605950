import axios from "axios";
import { baseUrlNoAuth, getOptionsNoAuth } from "../config.js";

class ProductsService {
  constructor(baseUrl) {
    this.basePath = "/products";
    this.baseUrl = `${baseUrlNoAuth}${this.basePath}`;
  }

  getProducts(data={}) {
    return axios(getOptionsNoAuth(this.baseUrl, "", data));
  }

  getProductsStandalone(data={}) {
    return axios(getOptionsNoAuth(this.baseUrl, "/.standalone", data));
  }

  getFeatures() {
    return axios(getOptionsNoAuth(this.baseUrl, `/features`));
  }

  getProductReference(data) {
    return axios(getOptionsNoAuth(this.baseUrl, `/reference`, data));
  }

  getProductReferenceStandalone(data) {
    return axios(getOptionsNoAuth(this.baseUrl, `/reference.standalone`, data));
  }

  getProductCompatibility(id) {
    return axios(
      getOptionsNoAuth(this.baseUrl, `/reference/${id}/compatibility`)
    );
  }

  getCancellationProductReference(data) {
    return axios(
      getOptionsNoAuth(this.baseUrl, `/reference/cancellation`, data)
    );
  }
}

export default new ProductsService(baseUrlNoAuth);  //eslint-disable-line import/no-anonymous-default-export
