import React, { useEffect, useState } from "react";
import GenericError from "../Utilities/GenericError";
import PopUp from "../Utilities/PopUp";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";
import { useTranslation } from "react-i18next";
import {useLoading} from "../../context/LoadingContext";
import {useAuth} from "../../context/AuthContext";

function PasswordUpdate() {
  let navigate = useNavigate();

  const [open, setOpen] = useState(true);

  const { t } = useTranslation();

  const {removeLoading, isLoading} = useLoading()
  const { setCheckLoggedIn } = useAuth()

  useEffect(() => {
    var url_string = window.location;
    var url = new URL(url_string);
    var status = url.searchParams.get("kc_action_status");
    if (status !== "success") {
      navigate("/Account");
    }
    removeLoading("INIT");
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const goBackToAccount = () => {
    setCheckLoggedIn(false);
    navigate("/Account");
  };

  return (
    <div>
      <>
        {isLoading() ? (
          <Loader />
        ) : (
          <PopUp
            openPopUp={open}
            setOpenPopUp={setOpen}
            maxWidth="md"
            showCloseButton={false}
            outlined={false}
            error={false}
          >
            <GenericError
              mainText={t("passwordSuccessMain")}
              success={true}
              secondaryText={t("passwordSuccessSecondary")}
              buttonText={t("passwordSuccessButton")}
              setClose={setOpen}
              executeFunction={goBackToAccount}
            />
          </PopUp>
        )}
      </>
    </div>
  );
}

export default PasswordUpdate;
