import React, { useState } from "react";
import "./UsualQuestions.css";
import "../GlobalStyles.css";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";

import CmsHelper, { LandingPageHelper } from "../Utilities/CmsHelpers";
import {styled} from "@mui/system";
import {useCms} from "../../context/CmsContext";

const CustomAccordion = styled(Accordion)(({ theme }) => ({
    // padding: "0px 8px",
    backgroundColor: "#000000",
    color: "#FFFFFF",
    border: "2px solid #7F7F7F",
    textAlign: "left",
    borderRadius: "8px",
    "&:first-of-type": {
      borderRadius: "8px",
    },
    "&:last-child": {
      borderRadius: "8px",
    },
    "&:hover, &:focus": {
      borderColor: "#FF0078",
    },

}));
const CustomAddIcon = styled(AddIcon)(({ theme }) => ({
    color: "#FF0078"
}));

function addQuestion(title, paragraphs) {
  return {
    title: title,
    description: (
      <div>
        {
          paragraphs.map((item, index) => {

            return (
              <React.Fragment key={index}>
                <p key={index}>
                  {item.answer}
                </p>
                <ul>
                  {
                    CmsHelper.getDetails(item.details).map((dItem, dindex) => {
                      return <li key={dindex}>{dItem}</li>
                    })
                  }
                </ul>
              </React.Fragment>
            )
          })
        }
      </div>
    )
  }
}

function UsualQuestions() {
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const questions = [];

  const {landingData} = useCms();
  LandingPageHelper.getQuestionsSection(landingData).forEach(item => {
    questions.push(addQuestion(item.question, item.answer));
  });

  const sectionTitle = questions.length > 0 ? <h3>{t("homepageAccordeonTitle")}</h3> : null;

  return (<>
      {!!questions.length &&
        <div className="usual-questions-container">
          <div className="usual-questions box">
            {sectionTitle}
            {questions.map((item, index) => (
              <CustomAccordion
                key={index}
                expanded={expanded === `panel${index}`}
                onChange={handleAccordionChange(`panel${index}`)}>
                <AccordionSummary
                  expandIcon={<CustomAddIcon />}
                  aria-controls={`panel${index}a-content`}
                  id={`panel${index}a-header`}
                  className="homepage-accordion-title">
                  <Typography>{item.title}</Typography>
                </AccordionSummary>
                <AccordionDetails className="homepage-accordion-details">{item.description}</AccordionDetails>
              </CustomAccordion>
            ))}
          </div>
        </div>
      }</>);
}

export default UsualQuestions;
