import React from "react";
import "../GlobalStyles.css";
import "./GenericError.css";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";

function GenericError({
  setClose,
  mainText,
  secondaryText,
  success = false,
  buttonText,
  executeFunction,
  showIcon = true,
  hideButtons = false,
  children
}) {
  const { t } = useTranslation();

  const goBack = () => {
    if (executeFunction) {
      executeFunction();
    }

    setClose(false);
  };

  return (
    <div className="generic-error-wrapper box">
      <div className={`base-message ${!showIcon ? "centered" : ""}`}>
        {!success ? (
          <>{showIcon && <ErrorOutlineIcon id="gn-icon" />}</>
        ) : (
          <>{showIcon && <CheckCircleOutlineIcon id="gn-icon" />}</>
        )}
        {mainText ? mainText : ""}
      </div>
      <div className={"secondary-wrapper"}>
        {secondaryText &&
          <div className="secondary-message">
            {secondaryText}
          </div>
        }
        {children}
      </div>

      {!hideButtons && (
        <div>
          <Button variant="contained" color="primary" onClick={goBack}>
            {buttonText ? buttonText : `${t("goBackButton")}`}
          </Button>
        </div>
      )}
    </div>
  );
}

export default GenericError;
