import React, { useEffect, useState } from "react";
import "../../../GlobalStyles.css";
import "./Questionnaire.css";
import Loader from "../../../Loader/Loader";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {Button} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import {QuestionnaireHelper} from "../../../Utilities/CmsHelpers";
import ProductsService from "../../../../services/ProductsService";
import QuestionnaireService from "../../../../services/QuestionnaireService";
import CheckBox from "../../components/CheckBox/CheckBox";
import { styled } from '@mui/system';
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/material";
import {useCms} from "../../../../context/CmsContext";
import {useAuth} from "../../../../context/AuthContext";
import {useAccount} from "../../../../context/AccountContext";

const TextareaAutosize = styled(BaseTextareaAutosize)(
    ({ theme }) => `
    box-sizing: border-box;
    width: 465px;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    padding: 16px;
    border-radius: 8px;
    color: #fff;
    background: #000;
  `,
);
function Questionnaire() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    let navigate = useNavigate();

    let account = useAccount();

    //this page should not use context loading since on submit we need to start loading and navigate
    const [loading, setLoading] = useState(true);


    const {setCheckLoggedIn} = useAuth();

    const onGoBack = () => {
        setCheckLoggedIn(false);
        navigate("/Account");
    };

    const {q, productId, pricePoint, next_assessment_at, subscriptionState} = location.state ?? {};
    const currentQ = q ? q : (!!searchParams.get('q') && searchParams.get("q") !== "unsubscribe") ? searchParams.get("q") : undefined;

    if(!(!!currentQ)){
        navigate("/")
    }

    const [answersArray, setAnswersArray] = useState([])
    const [checkboxes, setCheckboxes] = useState({});
    const [textArea, setTextArea] = useState("")


    const {questionnaireData} = useCms();
    const [questionData, setQuestionData] = useState(undefined)

    useEffect(()=>{
        setQuestionData(QuestionnaireHelper.getQuestionnaire(questionnaireData, currentQ))
    }, [questionnaireData, currentQ])//eslint-disable-line react-hooks/exhaustive-deps

    useEffect(()=>{
        setLoading(true)
        if(!!questionData)
            if(!(!!questionData.title) || !(!!questionData.question)){
                navigate("/")
            }else {
                setAnswersArray(arraySuffler(questionData?.Answers))
                setLoading(false)
            }
    }, [questionData]) //eslint-disable-line react-hooks/exhaustive-deps

    const unsubscribeFlow = ()=>{
        const isInThreeMonths = (inputDate) => {
            const date = new Date(inputDate);
            const inputDateOnly = new Date(date.getFullYear(), date.getMonth(), date.getDate());

            const currentDate = new Date();
            const currentDateOnly = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());

            const threeMonthsEarlier = new Date(inputDateOnly);
            threeMonthsEarlier.setMonth(threeMonthsEarlier.getMonth() - 3);

            return currentDateOnly >= threeMonthsEarlier;
        };

        ProductsService.getCancellationProductReference({
            productId: productId,
            pricePoint: pricePoint,
        })
            .then((response) => {
                if (response.data.upgrades.length > 0 && !(subscriptionState === "trialing") ) {
                    if(!isInThreeMonths(next_assessment_at)){
                        setCheckLoggedIn(false);
                        navigate("/Unsubscribe");
                        return;
                    }
                    account.setMigrateFromCancellation(true);
                    setLoading(false);
                    navigate("/PickProgram");
                } else {
                    setCheckLoggedIn(false);
                    navigate("/Unsubscribe");
                }
            })
            .catch((error) => {
                setCheckLoggedIn(false);
                navigate("/Unsubscribe");
            });
    }

    const onSubmit = ()=>{
        setLoading(true);
        const checkedValues = [...Object.keys(checkboxes)
            .filter(key =>  key !== "Other" && checkboxes[key])
            .map(key => key.substring(0, 50)),
            ...(checkboxes["Other"] ? ["Other: "+textArea] : [])];
        QuestionnaireService.submitQuestionnaire({questionnaireId:currentQ, answers:checkedValues})
            .then(response =>{
            })
            .catch(error =>{
                console.error(error);
            })
        if(q && q === "unsubscribe"){
            unsubscribeFlow();
        }else{
            navigate("/")
        }
    }

    const arraySuffler = (array)=>{
        if(!!array){
            const newArray = [...array];
            for (let i = newArray.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
            }
            return newArray;
        }else {
            return []
        }
    }

    const handleCheckboxChange = (name, checked) => {
        setCheckboxes({
            ...checkboxes,
            [name]: checked
        });
    };

    return (
        <>
            {loading ? (
                <Loader/>
            ) : (
                <div className="questionnaire-wrapper">
                    <h1>{questionData?.title}</h1>
                    <div className={"questionnaire-content"}>
                        <Button
                            variant="text"
                            className="blue questionnaire-goBack"
                            id="goBackButton"
                            onClick={onGoBack}
                        >
                            <ArrowBackIcon />
                            Πίσω
                        </Button>
                        <div className={"questionnaire-questions"}>
                            <div>
                                <h2>{questionData?.question}</h2>
                                <span>Επίλεξε μία ή περισσότερες από τις παρακάτω επιλογές.</span>
                            </div>
                            <ul>
                                {answersArray.map((item, index) =>
                                    <li key={index}>
                                        <CheckBox text={item.answer}
                                                  id={item.answer}
                                                  checked={checkboxes[item.answer] || false}
                                                  action={(e)=>{handleCheckboxChange(item.answer, e.target.checked)}}>
                                        </CheckBox>
                                    </li>
                                )}
                                <li>
                                    <CheckBox
                                        id={"Αλλο"}
                                         checked={checkboxes["Other"] || false}
                                        action={(e)=>{handleCheckboxChange("Other", e.target.checked)}}>
                                        <div className={"questionnaire-other-wrapper"}>
                                            <span>Άλλο</span>
                                            {(checkboxes["Other"] || false) &&
                                                <div className={"questionnaire-other-hidden-wrapper"}>
                                                    <TextareaAutosize minRows={3} placeholder={"Γράψε εδώ τον λόγο..."} value={textArea} onChange={(e)=>{setTextArea(e.target.value)}} />
                                                    <span className={"questionnaire-other-hidden-span"}>*Προαιρετικό</span>
                                                </div>
                                            }
                                        </div>
                                    </CheckBox>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className={"questionnaire-submit-wrapper"}>
                        <button className={"questionnaire-submit"} disabled={!(!!Object.keys(checkboxes).filter(key => checkboxes[key]).length)} onClick={()=>onSubmit()}>Υποβολή</button>
                    </div>
                </div>
            )}
        </>
    );
}

export default Questionnaire;
