window.dataLayer = window.dataLayer || [];

function gtag() {
  window.dataLayer.push(arguments);
}

function onRegister() {
  !!window.gtag && window.gtag("event", "Register", {
    event_category: "Register",
    event_label: "User registers to ant1+",
    dp: "/Register",
  });
}

function onRegisterConfirm() {
  !!window.gtag && window.gtag("event", "Register", {
    event_category: "Register Confirm",
    event_label: "User registers to ant1+ confirmation",
    dp: "/signup/confirm",
  });
}

function onLogIn() {
  !!window.gtag && window.gtag("event", "Login", {
    event_category: "Login",
    event_label: "User logs in to ant1+",
    dp: "/Login",
  });
}

function onSelectPackage() {
  !!window.gtag && window.gtag("event", "Select Product", {
    event_category: "Select Product",
    event_label: "User selects product",
    dp: "/signup/select",
  });
}

function onCongratsLoad(product_name) {
  var url = "/signup/welcome-" + product_name;
  gtag("event", product_name, {
    event_category: "Welcome",
    event_label: "User has selected product and can start watching",
    dp: url,
  });
}

function onProductConversion(analyticsProductConversion){
  if(analyticsProductConversion !== null){
    window.dataLayer.push({
      'event':'ec_formsubmit',
      'enhanced_conversion_data': {
        ...analyticsProductConversion
      }
    })
  }
}


const GoogleAnalytics = {
  onRegister,
  onLogIn,
  onSelectPackage,
  onCongratsLoad,
  onRegisterConfirm,
  onProductConversion,
};

export default GoogleAnalytics;
