import axios from "axios";
import { baseUrl, otpPostOptions } from "../config.js";
class CardValidationService {
  constructor(baseUrl) {
    this.basePath = "/otp";
    this.baseUrl = `${baseUrl}${this.basePath}`;
  }

  generateOTP(data, token) {
    return axios(otpPostOptions(this.baseUrl, "", data, token));
  }

  verifyOTP(data, token) {
    return axios(otpPostOptions(this.baseUrl, "/token", data, token));
  }
}

export default new CardValidationService(baseUrl);  //eslint-disable-line import/no-anonymous-default-export
