import React, {useState, useEffect, useRef} from "react";
import "./CardCarousel.css"
import "../../Account.css"
import PopUp from "../../../Utilities/PopUp";
import GenericError from "../../../Utilities/GenericError";
import {Button} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useLoading} from "../../../../context/LoadingContext";
import AccountsService from "../../../../services/AccountsService";
function CardCarousel({list}) {
    const cardDesktopWidth = 317;
    const cardDesktopHeight = 190;
    const cardDesktopSmallWidth = 273;
    const cardDesktopSmallHeight = 164;

    const cardMobileWidth = 273;
    const cardMobileHeight = 164;
    const cardMobileSmallWidth = 218;
    const cardMobileSmallHeight = 131;
    
    const [cardWidth, setCardWidth] = useState();
    const [cardHeight, setCardHeight] = useState();
    const [cardSmallWidth, setCardSmallWidth] = useState();
    const [cardSmallHeight, setCardSmallHeight] = useState();
    
    
    const gapBetween = 16;
    const hiddenWidth = 75;
    const listLength = list.length;

    const [activeSlide, setActiveSlide] = useState(0);

    const next = () =>
        activeSlide < list.length - 1 && setActiveSlide(activeSlide + 1);

    const prev = () => activeSlide > 0 && setActiveSlide(activeSlide - 1);

    const [containerWidth, setContainerWidth] = useState(0);
    const [numCards, setNumOfCards] = useState(1);

    useEffect(() => {
        const handleResize = () => {
            setContainerWidth(document.getElementById("container").offsetWidth);
        };

        window.addEventListener("resize", handleResize);
        handleResize(); // Call the handler initially

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const [isMobile, setIsMobile] = useState(false)
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(document.body.offsetWidth <= 610);
        };

        window.addEventListener("resize", handleResize);
        handleResize(); // Call the handler initially

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        const maxCards = Math.floor(containerWidth / cardWidth);
        if (
            containerWidth <
            Math.min(maxCards, listLength) * (cardWidth+gapBetween) +
            (listLength - Math.min(maxCards, listLength)) * hiddenWidth
        ) {
            setNumOfCards(Math.min(maxCards - 1, listLength));
        } else {
            setNumOfCards(Math.min(maxCards, listLength));
        }
    }, [containerWidth]);//eslint-disable-line react-hooks/exhaustive-deps

    useEffect(()=>{
        if(isMobile){
            setCardWidth(cardMobileWidth)
            setCardHeight(cardMobileHeight)
            setCardSmallWidth(cardMobileSmallWidth)
            setCardSmallHeight(cardMobileSmallHeight)
        }else{
            setCardWidth(cardDesktopWidth)
            setCardHeight(cardDesktopHeight)
            setCardSmallWidth(cardDesktopSmallWidth)
            setCardSmallHeight(cardDesktopSmallHeight)
        }
    }, [isMobile])


    const [fittsAll, setFittsAll] = useState(false);
    useEffect(()=>{
        setFittsAll(numCards === list.length)
    }, [numCards, list])

    useEffect(()=>{
        if(fittsAll){
            setActiveSlide(0);
        }
    }, [fittsAll])

    const getStyles = (index) => {
        if(isMobile){
            if (activeSlide === index)
                return {
                    opacity: 1,
                    transform: "translateX(0px) translateZ(0px) rotateY(0deg)",
                    zIndex: 10,
                };
            else if (activeSlide - 1 === index)
                return {
                    opacity: 1,
                    transform: "translateX(-140px) translateZ(-400px) rotateY(35deg)",
                    zIndex: 9,
                };
            else if (activeSlide + 1 === index)
                return {
                    opacity: 1,
                    transform: "translateX(140px) translateZ(-400px) rotateY(-35deg)",
                    zIndex: 9,
                };
            else if (index < activeSlide - 1)
                return {
                    opacity: 0,
                    transform: "translateX(-140px) translateZ(-500px) rotateY(35deg)",
                    zIndex: 7,
                };
            else if (index > activeSlide + 1)
                return {
                    opacity: 0,
                    transform: "translateX(140px) translateZ(-500px) rotateY(-35deg)",
                    zIndex: 7,
                };
        }else{
            for (var j = 0; j < activeSlide; j++) {
                if (index === j) {
                    return {
                        opacity: 1,
                        transform: `translateX(${hiddenWidth * j}px) translateZ(-100px) rotateY(0deg)`,
                        zIndex: 10,
                        // background: `blue`,
                    };
                }
            }

            for (var k = 0; k < numCards; k++) {
                if (index === k + activeSlide) {
                    if(fittsAll){
                        if(index === 0){
                            return {
                                opacity: 1,
                                transform: `translateX(${(cardWidth+gapBetween) * k + hiddenWidth * activeSlide
                                }px) translateZ(0px) rotateY(0deg)`,
                                zIndex: 10,
                                // background: `blue`,
                            };
                        }else{
                            return {
                                opacity: 1,
                                transform: `translateX(${(cardWidth+gapBetween)+ (cardSmallWidth+gapBetween) * (k-1) + hiddenWidth * activeSlide
                                }px) translateY(${cardHeight-cardSmallHeight}px) translateZ(0px) rotateY(0deg)`,
                                zIndex: 10,
                                // background: `blue`,
                            };
                        }
                    }else{
                        return {
                            opacity: 1,
                            transform: `translateX(${(cardWidth+gapBetween) * k + hiddenWidth * activeSlide
                            }px) translateZ(0px) rotateY(0deg)`,
                            zIndex: 10,
                            // background: `blue`,
                        };
                    }
                }
            }

            for (var i = 0; i < listLength - numCards; i++) {
                if (index === i + numCards + activeSlide) {
                    return {
                        opacity: 1,
                        transform: `translateX(${
                            (cardWidth+gapBetween) * (numCards - 1) + hiddenWidth * (i + 1 + activeSlide) + (24 + gapBetween * (numCards-1))
                        }px) translateZ(-100px) rotateY(0deg)`,
                        zIndex: 10 - i - numCards,
                        textAlign: "right",
                        // background: `blue`,
                    };
                }
            }
        }
    };

    const [hasNext, setHasNext] = useState(true)
    useEffect(()=>{
        setHasNext(hasNextFunc(activeSlide))
    }, [activeSlide, numCards, list]) //eslint-disable-line react-hooks/exhaustive-deps

    const hasNextFunc = (index) => ((index+1)+ (numCards - 1)+1) <= list.length




    // the required distance between touchStart and touchEnd to be detected as a swipe
    const [touchStart, setTouchStart] = useState(null);
    const [touchEnd, setTouchEnd] = useState(null);

    const minSwipeDistance = 50;
    const onTouchStart = (e) => {
        setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
        setTouchStart(e.targetTouches[0].clientX);
    };

    const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

    const onTouchEnd = (id) => {
        if (!touchStart || !touchEnd) return;
        const distance = touchStart - touchEnd;
        const isLeftSwipe = distance > minSwipeDistance;
        const isRightSwipe = distance < -minSwipeDistance;
        if(isLeftSwipe) {
            if(id !== list.length-1){
                next()
            }
        }else if(isRightSwipe){
            if(id!==0){
                prev()
            }
        }
    };



    return (
        <div className={`container-wrapper ${isMobile ? "mobile" : ""}`}>
            {!isMobile && !fittsAll && activeSlide > 0 ?
                <div className={"card-carousel-arrow-wrapper"}>
                    <img src={"images/account/prevCardArrow.svg"} className={"card-carousel-arrowBack"} alt={"next card"} onClick={prev} /></div>
                    :<></>
            }
            <div id="container" className={`container ${!isMobile && !fittsAll ? "hasArrows" : ""}`}>
                <div className={`slideC ${isMobile ? "mobile" : ""}`} id="slideC">
                    {list.map((item, i) => {
                        const isActiveCard = i===0;
                        return (
                            <React.Fragment key={i}>
                                <div
                                    className="slide"
                                    style={{
                                        ...getStyles(i),
                                    }}
                                    onClick={()=>{
                                        if(!fittsAll) {
                                            if(numCards > 1) {
                                                if (!hasNextFunc(i - 1)) {
                                                    setActiveSlide(i - 1)
                                                } else {
                                                    if (hasNextFunc(i)) {
                                                        setActiveSlide(i)
                                                    }
                                                }
                                            } else {
                                                setActiveSlide(i)
                                            }
                                        }

                                    }}
                                    onTouchStart={isMobile ? onTouchStart : null}
                                    onTouchMove={isMobile ? onTouchMove : null}
                                    onTouchEnd={isMobile ? () => {
                                        onTouchEnd(i);
                                    } : null}
                                >
                                    <PaymentProfile paymentProfile={item} isActiveCard={isActiveCard} small={!isActiveCard && fittsAll}/>
                                </div>
                            </React.Fragment>
                        )
                    })}
                </div>
                {isMobile && list.length > 1 && (
                    <div className="dots">
                        {list.map((slide, index) => (
                            <span
                                className={`dot ${activeSlide === index ? "active" : ""}`}
                                onClick={() => setActiveSlide(index)}
                                key={index}
                            ></span>
                        ))}
                    </div>
                )}
            </div>
            {!isMobile && !fittsAll && hasNext ?
                <div className={"card-carousel-arrow-wrapper"}>
                    <img src={"images/account/nextCardArrow.svg"} className={"card-carousel-arrowNext"} alt={"next card"} onClick={next} /></div>
                    :<></>
            }
        </div>
    );
}



function PaymentProfile({paymentProfile, isActiveCard, small}){
    const navigate = useNavigate();
    const {addLoading} = useLoading()
    const changeActiveCard = ()=>{
        addLoading("CHANGEACTIVECARD")
        AccountsService.selectPaymentProfile({paymentId: paymentProfile?.id})
            .then(response => {

            })
            .catch(error => {
                console.log("Error changing active payment method")
            })
            .finally(()=>{
                navigate(0);
            })
    }
    const deleteCard = ()=>{
        addLoading("DELETECARD")
        AccountsService.deletePaymentProfile({paymentId: paymentProfile?.id})
            .then(response => {

            })
            .catch(error => {
                console.log("Error deleting payment method")
            })
            .finally(()=>{
                navigate(0);
            })
    }
    const [isOpen, setIsOpen] = useState(false)
    // const [activeElement, setActiveElement] = useState(false);

    const trigger = useRef(null)
    const menu = useRef(null)

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!menu?.current?.contains(event.target) && !trigger?.current?.contains(event.target)) {
                setIsOpen(false);
                // setActiveElement(false);
            }else{
                // setActiveElement(true);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
    }, [trigger, menu])

    const [popupOpen, setPopupOpen] = useState(false);


    return (
        <div className={`card-carousel ${small ? "card-carousel-small" : ""} ${isActiveCard ? "selected" : ""}`}>
            <div className={"card-top"}>
                {paymentProfile?.card_type === "master" ?
                    <img className={"cardType-icon"}
                         src={"images/account/mastercard_logo.svg"} alt={"master card icon"}/>
                    :
                    <img className={"cardType-icon"}
                         src={"images/account/visacard_logo.svg"} alt={"visa card icon"}/>
                }
                {isActiveCard ?
                    <span className={"active-text"}>
                        <img className={"active-text-check"} src={"images/activeCardCheck.svg"} alt={"active card"}/>
                        Ενεργή κάρτα
                    </span>
                    :
                    <div className={"settings-wrapper"}>
                        {isOpen ?
                            <img className={"settings-icon"}
                                 src={"images/account/gearSettingsActive.svg"} alt={"active card settings"} ref={trigger} onClick={()=>{setIsOpen(state => !state)}}/>:
                            <img className={"settings-icon"}
                                 src={"images/account/gearSettings.svg"} alt={"non-active card settings"} ref={trigger} onClick={()=>{setIsOpen(state => !state)}}/>
                        }
                        {isOpen &&
                            <div className={"settings-dropdown"} ref={menu}>
                                <button onClick={changeActiveCard}>Επιλογή ως ενεργή κάρτα</button>
                                <button onClick={()=> {
                                    setPopupOpen(true);
                                }}>Διαγραφή κάρτας</button>
                            </div>
                        }
                    </div>
                }
            </div>
            <div className={"card-bot"}>
                <span>
                    ****
                    {paymentProfile?.masked_card_number
                        .slice(paymentProfile?.masked_card_number?.length - 4,
                            paymentProfile?.masked_card_number?.length)}
                </span>
                    <span>
                        {paymentProfile?.expiration_month}
                        /
                        {paymentProfile?.expiration_year
                            .slice(paymentProfile?.expiration_year?.length - 2,
                            paymentProfile?.expiration_year?.length)}
                    </span>
            </div>


            <PopUp
                openPopUp={popupOpen}
                setOpenPopUp={setPopupOpen}
                maxWidth="md"
                showCloseButton={true}
                outlined={false}
                error={false}
                closeWhenClickedOutside={true}
            >
                <GenericError
                    setClose={setPopupOpen}
                    success={true}
                    mainText={"Διαγραφή κάρτας"}
                    secondaryText={"Η ενέργεια αυτή είναι μη αναστρέψιμη και θα αφαιρέσει την κάρτα από τον λογαριασμό σου."}
                    hideButtons={true}
                    showIcon={false}>
                    <div className={"account-card-delete-wrapper"}>
                        <Button variant="outlined" color="primary" onClick={()=>{setPopupOpen(false)}}>
                            Επιστροφή
                        </Button>
                        <Button variant="contained" color="primary" onClick={deleteCard}>
                            Διαγραφή κάρτας
                        </Button>
                    </div>
                </GenericError>
            </PopUp>
        </div>
    )
}

export default CardCarousel;