import {createTheme} from '@mui/material';


const t = createTheme();

export const theme = createTheme({
  palette: {
    primary: {
      main: "#FF0078",
      light: "#FF0078",
      dark: "#FF0078",
    },
    secondary: {
      main: "#3E4144",
      light: "#3E4144",
    },
    white: {
      main: "#FFFFFF",
      light: "#FFFFFF",
    },
    blue: {
      main: "#0387FF",
      light: "#0387FF",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontWeight:"700"
        },
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: "#000000",
          color: "#FFFFFF",
          border: "2px solid #7F7F7F",
          textAlign: "left",
          margin: "8px 0",
          borderRadius: "8px"
        },
        rounded: {
          "&:first-of-type": {
            marginTop: "0",
          },
          width: "100%",
        },
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: "100%",
          maxWidth: "350px",
        },
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          maxHeight: "36px",
        },
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: "8px 16px",
          backgroundColor: "none",
        },
        adornedEnd: {
          paddingRight: "0px",
        },
        root: {
          color: "white",
          // Hover state
          "&:hover $notchedOutline": {
            borderColor: "white",
          },
          // Focused state
          "&$focused $notchedOutline": {
            borderColor: "#02549F",
          },
          //error state
          "&$error $notchedOutline": {
            borderColor: "#C64E4E",
          },
        },
        // Default State
        notchedOutline: {
          borderColor: "white",
        },
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
        },
      }
    },
    MuiSelect: {
      styleOverrides: {
        selectMenu: {
          overflow: "inherit",
        },
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'normal',
          fontSize: '',
          fontWeight: '400',
          color: 'rgba(255, 255, 255, 0.9)',
          paddingInline: '0',
          lineHeight: '120%',
          "&.Mui-selected": {},
          [t.breakpoints.up('sm')]: {
            textTransform: 'normal',
            fontSize: '24px',
          }

        }
      }
    },
  },
});