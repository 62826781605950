import React, {useEffect, useRef, useState} from 'react';
import './DatePicker.css';

const CustomDatePicker = ({fieldTitle, titleInfo, disabled = false, defaultValue, onChange}) => {
    const [selectedDate, setSelectedDate] = useState(defaultValue? new Date(defaultValue):null);
    const [displayedMonth, setDisplayedMonth] = useState(new Date(defaultValue? new Date(defaultValue):null));
    const [isYearSelection, setIsYearSelection] = useState(false);
    const [isMonthSelection, setIsMonthSelection] = useState(false);

    const [isOpen, setIsOpen] = useState(false);
    const trigger = useRef(null)
    const menu = useRef(null)

    const [activeElement, setActiveElement] = useState(false);
    useEffect(() => {

        const handleClickOutside = (event) => {
            if (!menu?.current?.contains(event.target) && !trigger?.current?.contains(event.target)) {
                setIsOpen(false);
                setActiveElement(false);
            }else{
                setActiveElement(true);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
    }, [trigger, menu])

    const handleDayClick = ()=>{
        setIsMonthSelection(false);
        setIsYearSelection(false);
    }

    const handleMonthClick = () => {
        setIsMonthSelection(true);
        setIsYearSelection(false);
    };

    const handleYearClick = () => {
        setIsMonthSelection(false);
        setIsYearSelection(true);
    };

    const handleDateChange = (date) => {
        setDisplayedMonth(date);
        setHasSelectedDate(true);
        if(hasSelectedMonth && hasSelectedYear){
            setSelectedDate(date);
            setIsOpen(false);
        }else if(hasSelectedMonth){
            setIsYearSelection(true);
            setIsMonthSelection(false);
        }else{
            setIsYearSelection(false);
            setIsMonthSelection(true);
        }
    };

    const handleMonthChange = (month) => {
        const today = new Date();
        const currentYear = today.getFullYear();


        if(hasSelectedYear){
            if(hasSelectedDate){
                const currentSelection = new Date(displayedMonth.getFullYear(), month+1, 0);
                if(displayedMonth.getDate() > currentSelection.getDate()){
                    setHasSelectedMonth(true);
                    setDisplayedMonth(new Date(displayedMonth.getFullYear(), month))
                    setIsYearSelection(false);
                    setIsMonthSelection(false);
                }else{
                    setIsMonthSelection(true);
                    setDisplayedMonth(new Date(displayedMonth.getFullYear(), month, displayedMonth.getDate()))
                    setIsYearSelection(false);
                    setIsMonthSelection(false);
                    setSelectedDate(new Date(displayedMonth.getFullYear(), month, displayedMonth.getDate()))
                    setIsOpen(false);
                }
            }else{
                setIsMonthSelection(true);
                setDisplayedMonth(new Date(displayedMonth.getFullYear(), month))
                setIsYearSelection(false);
                setIsMonthSelection(false);
            }
        }else{
            if(hasSelectedDate){
                const currentSelection = new Date(currentYear, month+1, 0);
                if(currentSelection.getDate() > 29){
                    setHasSelectedMonth(true);
                    setDisplayedMonth(new Date(currentYear, month, displayedMonth.getDate()));
                    setIsYearSelection(true);
                    setIsMonthSelection(false);
                }else{
                    if(displayedMonth.getDate() > currentSelection.getDate()){
                        setHasSelectedDate(false);
                        setHasSelectedMonth(true);
                        setDisplayedMonth(new Date(currentYear, month));
                        setIsYearSelection(false);
                        setIsMonthSelection(false);
                    }else{
                        setHasSelectedMonth(true);
                        setDisplayedMonth(new Date(currentYear, month, displayedMonth.getDate()))
                        setIsYearSelection(true);
                        setIsMonthSelection(false);
                    }
                }
            }else{
                setHasSelectedMonth(true);
                setDisplayedMonth(new Date(currentYear-1, month))
                setIsYearSelection(true);
                setIsMonthSelection(false);
            }
        }
    };

    const handleYearChange = (year) => {
        const today = new Date();
        const currentYear = today.getFullYear();
        const currentMonth = today.getMonth()+1;
        const currentDate = today.getDate();

        if(year === currentYear){
            if(hasSelectedMonth){
                if(displayedMonth.getMonth() > currentMonth){
                    setHasSelectedYear(true);
                    setDisplayedMonth(new Date(year, currentMonth));
                    setHasSelectedMonth(false);
                    setHasSelectedDate(false);
                    setIsYearSelection(false);
                    setIsMonthSelection(true);
                }else if(displayedMonth.getMonth() === currentMonth){
                    if(hasSelectedDate){
                        if(displayedMonth.getDate() > currentDate){
                            setHasSelectedDate(false);
                            setDisplayedMonth(new Date(year, displayedMonth.getMonth()))
                            setIsMonthSelection(false);
                            setIsYearSelection(false);
                        }else{
                            setHasSelectedYear(true);
                            setDisplayedMonth(new Date(year, displayedMonth.getMonth(), displayedMonth.getDate()))
                            setIsYearSelection(false);
                            setIsMonthSelection(false);
                            setSelectedDate(new Date(year, displayedMonth.getMonth(), displayedMonth.getDate()));
                            setIsOpen(false)
                        }
                    }else{
                        setHasSelectedDate(false);
                        setHasSelectedYear(true);
                        setDisplayedMonth(new Date(year, displayedMonth.getMonth()))
                        setIsYearSelection(false);
                        setIsMonthSelection(false);
                    }
                }else{
                    if(hasSelectedDate){
                        const currentSelected = new Date(year, displayedMonth.getMonth()+1, 0);
                        if(displayedMonth.getDate() > currentSelected.getDate()){
                            setHasSelectedDate(false);
                            setDisplayedMonth(new Date(year, displayedMonth.getMonth()))
                            setIsYearSelection(false);
                            setIsMonthSelection(false);
                        }else {
                            setHasSelectedYear(true);
                            setDisplayedMonth(new Date(year, displayedMonth.getMonth(), displayedMonth.getDate()))
                            setIsYearSelection(false);
                            setIsMonthSelection(false);
                            setSelectedDate(new Date(year, displayedMonth.getMonth(), displayedMonth.getDate()))
                            setIsOpen(false);
                        }
                    }else{
                        setHasSelectedYear(true);
                        setDisplayedMonth(new Date(year, displayedMonth.getMonth()))
                        setIsMonthSelection(false)
                        setIsYearSelection(false);
                    }
                }
            }else{
                setHasSelectedYear(true)
                setDisplayedMonth(new Date(year, currentMonth))
                setIsYearSelection(false)
                setIsMonthSelection(true);
            }
        }else{
            if(hasSelectedMonth){
                if(hasSelectedDate){
                    const currentSelected = new Date(year, displayedMonth.getMonth()+1, 0)
                    if(displayedMonth.getDate() > currentSelected.getDate()){
                        setHasSelectedDate(false);
                        setHasSelectedYear(true);
                        setDisplayedMonth(new Date(year, displayedMonth.getMonth()));
                        setIsYearSelection(false);
                        setIsMonthSelection(false);
                    }else{
                        setHasSelectedYear(true);
                        setDisplayedMonth(new Date(year, displayedMonth.getMonth(), displayedMonth.getDate()))
                        setIsYearSelection(false);
                        setIsMonthSelection(false);
                        setSelectedDate(new Date(year, displayedMonth.getMonth(), displayedMonth.getDate()))
                        setIsOpen(false);
                    }
                }else{
                    setHasSelectedYear(true);
                    setDisplayedMonth(new Date(year, displayedMonth.getMonth()));
                    setIsYearSelection(false);
                    setIsMonthSelection(false);
                }
            }else{
                setHasSelectedDate(false);
                setHasSelectedYear(true)
                setDisplayedMonth(new Date(year, currentMonth));
                setIsYearSelection(false);
                setIsMonthSelection(true);
            }
        }
    };

    useEffect(()=>{
        if(!!selectedDate){
            onChange(selectedDate.toISOString());
        }
    }, [selectedDate]) //eslint-disable-line react-hooks/exhaustive-deps

    const renderYears = () => {
        const years = [];
        const currentYear = new Date().getFullYear();
        for (let i = currentYear - 100; i <= currentYear; i++) {
            years.push(
                <div
                    key={i}
                    className={`year ${displayedMonth.getFullYear() === i ? 'selected' : ''}`}
                    onClick={() => handleYearChange(i)}
                >
                    {i}
                </div>
            );
        }
        return years;
    };

    const handlePopup = () => {
        if(!!selectedDate){
            setDisplayedMonth(selectedDate);
        }
        setIsYearSelection(false);
        setIsMonthSelection(false);


        setIsOpen(prevState => !prevState)
    };

    const [fullCalendar, setFullCalendar] = useState()

    useEffect(()=>{
        let daysInMonth;
        if(hasSelectedYear){
            if(hasSelectedMonth){
                daysInMonth = new Date(displayedMonth.getFullYear(), displayedMonth.getMonth()+1, 0).getDate();
            }else{
                daysInMonth = 31;
            }
        }else{
            if(hasSelectedMonth){
                const temp = new Date(new Date().getFullYear(), displayedMonth.getMonth()+1, 0).getDate();
                if(temp > 29){
                    daysInMonth = 31;
                }else{
                    daysInMonth = 29;
                }
            }else{
                daysInMonth = 31;
            }
        }


        const today = new Date();
        const currentYear = today.getFullYear();
        const currentMonth = today.getMonth()+1;
        const currentDate = today.getDate();

        const days = [];
        // ["Δ", "Τ", "Τ", "Π", "Π", "Σ", "Κ"].forEach((i, index)=> {
        //     return days.push(<div key={`empty-weekday-${index}`} className="weekday">{i}</div>);
        // })
        // for (let i = 0; i < firstDayOfMonth; i++) {
        //     days.push(<div key={`empty-${i}`} className="empty-day"></div>);
        // }
        for (let i = 1; i <= daysInMonth; i++) {
            let isDisabled = false;
            if (
                displayedMonth &&
                displayedMonth.getFullYear() >= currentYear &&
                displayedMonth.getMonth() >= currentMonth &&
                i > currentDate
            ) {
                isDisabled = true;
            }
            days.push(
                <div
                    key={i}
                    className={`day ${isDisabled ? 'disabled' : ''}`}
                    onClick={() => !isDisabled && handleDateChange(new Date(displayedMonth.getFullYear(), displayedMonth.getMonth(), i))}
                >
                    {i}
                </div>
            );
        }
        setFullCalendar(days);
    }, [displayedMonth]); //eslint-disable-line react-hooks/exhaustive-deps

    const [monthsCalendar, setMonthsCalendar] = useState()
    const greekMonths=["Ιανουάριος", "Φεβρουάριος", "Μάρτιος", "Απρίλιος", "Μάιος", "Ιούνιος", "Ιούλιος", "Αύγουστος", "Σεπτέμβριος", "Οκτώβριος", "Νοέμβριος", "Δεκέμβριος"]
    useEffect(()=>{
        const today = new Date();
        const currentYear = today.getFullYear();
        const currentMonth = today.getMonth();

        const months = Array.from({ length: 12 }, (_, i) => {
            const monthDate = new Date(displayedMonth.getFullYear(), i);
            let isDisabled = false;
            if(hasSelectedYear){
                if(displayedMonth.getFullYear() === currentYear){
                    if(monthDate.getMonth() > currentMonth){
                        isDisabled = true;
                    }
                }
            }
            return (
                <div
                    key={i}
                    className={`month ${isDisabled ? 'disabled' : ''}`}
                    onClick={() => handleMonthChange(i)}
                >
                    {greekMonths[i]}
                </div>
            );
        });

        setMonthsCalendar(months)
    }, [displayedMonth]) //eslint-disable-line react-hooks/exhaustive-deps


    const [hasSelectedDate, setHasSelectedDate] = useState(!!defaultValue);
    const [hasSelectedMonth, setHasSelectedMonth] = useState(!!defaultValue);
    const [hasSelectedYear, setHasSelectedYear] = useState(!!defaultValue);

    return (
        <div className="custom-date-picker">
            <div className={"textbox-wrapper"}>
                <div className={"textbox-top"}>
                    <div className={"textbox-title-wrapper"}>
                        <span className={"textbox-title"}>{fieldTitle}</span>
                        {!!titleInfo &&
                            <img src={"images/account/fieldTitleInfo.svg"} alt={"info"}/>
                        }
                    </div>
                </div>
                <div className={`textbox-bot ${disabled ? "disabled" : ""}`} tabIndex={0}
                     onFocus={()=>{if(!disabled)handlePopup()}}
                     onBlur={()=>{ if(!disabled) {
                         if(!activeElement)
                             setIsOpen(false)
                     }}}
                     onMouseDown={()=>{if(isOpen) {setIsOpen(false)}else if(document.activeElement === trigger.current){if(!disabled)handlePopup();}}}
                     ref={trigger}>
                    <span className={"textbox-input date-input"}>
                        {selectedDate ?
                            selectedDate.getDate()+"/"+(selectedDate.getMonth()+1)+"/"+selectedDate.getFullYear()
                            : '--/--/----'
                        }
                    </span>
                    <img src={isOpen ? "images/datePickerIconColored.svg":"images/datePickerIcon.svg"} alt={"date picker icon"}/>
                    <input type={"date"} className={"textbox-date-hidden"} defaultValue={selectedDate?.toISOString()}/>
                </div>
            </div>



            {isOpen &&
                <div className="calendar-wrapper" ref={menu}>
                    <div className={"calendar-header"}>
                        <span onClick={handleDayClick}
                              className={`${isOpen && !isYearSelection && !isMonthSelection ? "selected": ""}`}>
                            {hasSelectedDate ?displayedMonth.getDate(): "--"}
                        </span>
                        <span onClick={handleMonthClick} className={`${isMonthSelection ? "selected": ""}`}>
                            {hasSelectedMonth ? greekMonths[displayedMonth.getMonth()] : "--"}
                        </span>
                        <span onClick={handleYearClick} className={`${isYearSelection ? "selected": ""}`}>
                            {hasSelectedYear ? displayedMonth.getFullYear() : "--"}
                        </span>
                    </div>
                    <div className="calendar">
                        {isYearSelection && (
                            <div className="years-container">{renderYears()}</div>
                        )}
                        {isMonthSelection && (
                            <div className="months-container">{monthsCalendar}</div>
                        )}
                        {!isYearSelection && !isMonthSelection &&
                            <div className="days-container">{fullCalendar}</div>
                        }
                    </div>
                </div>
            }

        </div>
    );
};

export default CustomDatePicker;
