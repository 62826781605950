import React from "react";
import "./TermsAndConditions.css";

function TermsAndConditions() {
  return (
    <div className="terms-and-conditions-wrapper">
      <iframe
        src="https://pages.antenna.gr/terms-conditions/"
        title="Terms & conditions"
      ></iframe>
    </div>
  );
}

export default TermsAndConditions;
