import "./ProductContainer.css"
import "./CurrentProductContainer.css"
import {useCms} from "../../../context/CmsContext";
import CmsHelper, {PickProgramPageHelper} from "../../Utilities/CmsHelpers";

export default function CurrentProductContainer({product}) {
    const {pickProgramData} = useCms();
    const programInfo = PickProgramPageHelper.getProgramInfo(PickProgramPageHelper.getPrograms(pickProgramData, CmsHelper.DEFAULT));
    const cmsData = PickProgramPageHelper.getProgram(programInfo, product.displayName)[0];

    return (<div className={`pick-product-wrapper current`}
                    id={product.displayName}>
                <div className={"pick-product-upper"}>
                    <span className={"pick-current-product-title"}>Έχεις ήδη το πρόγραμμα</span>
                    <span className={"pick-product-title"}
                          style={{backgroundColor: cmsData.displayName.colorCode+"4D"}}>{cmsData?.title}</span>
                </div>
                <div className={"pick-product-lower"}>
                    <div className={"pick-product-usps"}>
                        <ul>
                            {!!cmsData.infUsps &&
                                cmsData.infUsps.usp.map((usp, index) => {
                                        return <li key={index}>{usp.value}</li>
                                    })
                            }
                            {!!cmsData.usps && cmsData.usps.usp.map((usp, index) => {
                                return <li key={index}>{usp.value}</li>
                            })}
                        </ul>
                    </div>
                </div>
            </div>)
}