import { Button } from "@mui/material";
import { Alert } from "@mui/material";
import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import PinInput from "react-pin-input";
import DeviceLinkService from "../../services/DeviceLinkService";
import "../GlobalStyles.css";
import GenericError from "../Utilities/GenericError";
import PopUp from "../Utilities/PopUp";
import "./DeviceLink.css";
import Loader from "../Loader/Loader";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {useLoading} from "../../context/LoadingContext";

const style = {
  pinInput: {
    padding: "10px",
    display: "flex",
  },
  inputStyle: {
    color: "#FFFFFF",
    border: "1px solid #FFFFFF",
    backgroundColor: "#000000",
    borderRadius: "5px",
    fontSize: "40px",
    fontWeight: "700",
  },
  error: {
    border: "1px solid #C64E4E",
    borderRadius: "5px",
    color: "#C64E4E",
    backgroundColor: "#000000",
    fontSize: "40px",
    fontWeight: "700",
  },
};

function DeviceLink() {
  const { t } = useTranslation();

  const [error, setError] = useState(false);
  const [pin, setPin] = useState();
  // const [pinTest, setPinTest] = useState();
  const [openSuccessPopUp, setOpenSuccessPopUp] = useState(false);

  // useEffect(() => {
  //   DeviceLinkService.generateAuthTokens()
  //     .then((response) => {
  //       console.log("success 2");
  //       console.log(response.data.userCode);
  //       setError(false);
  //       console.log(parseInt(response.data.userCode.toString() + "0"));
  //       setPinTest(response.data.userCode);
  //       //setPinTest(parseInt(response.data.userCode.toString() + "0"));
  //     })
  //     .catch((error) => {
  //       setError(true);
  //       console.log(error.response);
  //     });
  // }, []);

  const {addLoading, isLoading, removeLoading} = useLoading()
  useEffect(() => {//this page is not interestead in loading, if we are here we are here.
    if(isLoading("INIT")){
      removeLoading("INIT")
    }
    // eslint-disable-next-line
  }, [isLoading]);

  const onPinComplete = (value) => {
    //setPin(pinTest);
    setPin(value);
  };

  const onVerifyPin = () => {
    addLoading("LINKVERIFYPIN")
    DeviceLinkService.linkUserWithDevice({ userCode: parseInt(pin) })
      .then((response) => {
        setError(false);
        setOpenSuccessPopUp(true);
      })
      .catch((error) => {
        setError(true);
      })
      .finally(()=>{
        removeLoading("LINKVERIFYPIN");
      })
  };

  return (
    <>
      {isLoading() ? (
        <Loader />
      ) : (
        <div className="device-link box big">
          <h3>{t("deviceLinkingTitle")}</h3>

          <div className="pin">
            <PinInput
              length={6}
              initialValue=""
              secret={false}
              onChange={(value, index) => {}}
              type="numeric"
              inputMode="number"
              style={style.pinInput}
              inputStyle={error ? style.error : style.inputStyle}
              inputFocusStyle={{ border: "1px solid rgba(0, 135, 255, 0.64)" }}
              onComplete={(value, index) => {
                onPinComplete(value);
              }}
              autoSelect={true}
              regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
              id="pin-input"
            />
            {error && (
              <Alert
                severity="error"
                id="pin-ver-alert"
                sx={{
                  backgroundColor: "transparent",
                  color: "#C64E4E",
                  padding: "0",
                }}
                icon={false}
              >
                {t("deviceLinkingErrorText")}
              </Alert>
            )}
          </div>

          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={onVerifyPin}
              id="link-verify-button"
            >
              {t("deviceLinkingButton")}
            </Button>
          </div>

          <div id="link-instructions">
            <div>
              <InfoOutlinedIcon id="info-icon"/>
              <div>Για να εμφανιστεί o κωδικός στην οθόνη της τηλεόρασης:</div>
            </div>
            <ul>
              <li>Άνοιξε την εφαρμογή AΝΤ1+ στη Smart TV</li>
              <li>Πάτησε Σύνδεση</li>
            </ul>
          </div>
          <PopUp
            openPopUp={openSuccessPopUp}
            setOpenPopUp={setOpenSuccessPopUp}
            maxWidth="md"
            showCloseButton={false}
            outlined={false}
            error={true}
          >
            <GenericError
              setClose={setOpenSuccessPopUp}
              success={true}
              mainText={t("deviceLinkingSuccessMainText")}
              secondaryText={t("deviceLinkingSuccessSecondaryText")}
              buttonText={t("deviceLinkingSuccessButtonText")}
            />
          </PopUp>
        </div>
      )}
    </>
  );
}

export default DeviceLink;
