import axios from "axios";
import { baseUrl, getOptions, getOptionsNoAuth } from "../config.js";

class CouponsService {
  constructor(baseUrl) {
    this.basePath = "/q/coupons";
    this.baseUrl = `${baseUrl}${this.basePath}`;
  }

  checkCouponValidity(couponCode) {
    //TODO: Alexis should review this
    var authenticated = !!JSON.parse(sessionStorage.getItem("kc_tok"))
    return axios(authenticated 
      ? getOptions(this.baseUrl,`/${couponCode}`)
      : getOptionsNoAuth(this.baseUrl,`/${couponCode}`));
  }

  getCouponProduct(couponCode) {
    //TODO: Alexis should review this
    var authenticated = !!JSON.parse(sessionStorage.getItem("kc_tok"))
    return axios(authenticated 
      ? getOptions(this.baseUrl,`/${couponCode}/product`)
      : getOptionsNoAuth(this.baseUrl,`/${couponCode}/product`));
  }

}

export default new CouponsService(baseUrl);  //eslint-disable-line import/no-anonymous-default-export