import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import UserService from "../../services/UserService";
import Loader from "../Loader/Loader";
import AccountsService from "../../services/AccountsService";
import AwsApiService from "../../services/AwsApiService";
import GoogleAnalytics from "../../analytics/GoogleAnalytics";
import CouponsService from "../../services/CouponsService";
import GenericError from "../Utilities/GenericError";
import PopUp from "../Utilities/PopUp";
import {useLoading} from "../../context/LoadingContext";
import {useAuth} from "../../context/AuthContext";
import PhoneVerification from "./PhoneVerification/PhoneVerification";
import ProductsService from "../../services/ProductsService";
import RegistrationFlow from "./FlowElements/RegistrationFlow/RegistrationFlow";
import {useAccount} from "../../context/AccountContext";
import MigrationFlow from "./FlowElements/MigrationFlow/MigrationFlow";

function PickProgram() {
  let navigate = useNavigate();

  const account = useAccount();

  const {isLoading, addLoading, removeLoading} = useLoading();
  const {loggedIn, authData} = useAuth()
  let location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const flow = queryParams.get("flow");
  const token = queryParams.get("token");//token from scheduled save desk

  const [verificationSent, setVerificationSent] = useState(false);

  const [phoneNumberVerifyToken, setPhoneNumberVerifyToken] = useState("");
  // const [phoneNum, setPhoneNum] = useState();

  const checkSubscriptionAction = async () => {
    if (UserService.isSubscriptionAction()) {
      // user is not initialized fully yet
      AwsApiService.onRegisterConfirm();
      GoogleAnalytics.onRegisterConfirm();

      try {
        const response = await AccountsService.checkSubscriptionPostAction();
        if ([2].includes(response.data.code)) {//todo check this
          UserService.resolveSubscriptionAction(response.data.token, false);
        } else {
          setVerificationSent(true)
          return true;
          // removeLoading("INIT");
        }
      }catch (error){
        if ([44].includes(error.response.data.code)) {
          navigate("/");
        } else if ([403].includes(error.response.data.code)) {
          if (error.response.data.token) { //phone verification flow
            setPhoneNumberVerifyToken(error.response.data.token)
          } else {
            //todo error return from back
          }
        }
      }
    } else {
      return true;
      // removeLoading("INIT")
    }
  }


  const [FlowElement, setFlowElement] = useState(<></>)


  const checkFlow = async () => {
    // let couponExists = false;
    let couponInfo = undefined;
    if (sessionStorage.getItem("discount-coupon")) {
      addLoading("PICKCOUPON")
      await CouponsService.getCouponProduct(sessionStorage.getItem("discount-coupon"))
          .then((response) => {
            couponInfo = response.data.references;
          })
          .catch((error) => {
            couponInfo = [];
            if(error.response.status !== 404){
              console.log("Error on getting coupon information");
            }
          })
          .finally(() => {
            removeLoading("PICKCOUPON")
          })
    }


    let promoRedirectURL;
    let promoTargetDisplayName;
    if (sessionStorage.getItem("promoRedirectURL")) {
      //flow promo
      promoRedirectURL = sessionStorage.getItem("promoRedirectURL")
      promoTargetDisplayName = sessionStorage.getItem("promoTargetDisplayName")

      sessionStorage.removeItem("promoRedirectURL")
      sessionStorage.removeItem("promoTargetDisplayName")
    }

    let contestSession;
    let contestRedirectURL;
    if (sessionStorage.getItem("contestSession")) {
      //flow contest
      contestSession = sessionStorage.getItem("contestSession")
      contestRedirectURL = sessionStorage.getItem("contestRedirectURL");

      sessionStorage.removeItem("contestSession");
      sessionStorage.removeItem("contestRedirectURL");
    }

    const handleNextStep = (isTrue)=> {
      if(isTrue){
        if(promoRedirectURL) {//pass promo information
          sessionStorage.setItem("congratsRedirectURL", promoRedirectURL)
        }
      }else{
        if(promoRedirectURL){//pass promo information
          sessionStorage.setItem("promoRedirectURL", promoRedirectURL)
        }

        if(contestSession){//pass contest information
          sessionStorage.setItem("contestSession", contestSession)
          sessionStorage.setItem("contestRedirectURL", contestRedirectURL)
        }
      }
    }

    if (token) {
      //flow token
      const response = await ProductsService.getProducts((token !== null ? {token: token} : {}))
          .then((response) => {
            return response.data.packages
          })
          .catch((error) => {
            return [];
          })

      setFlowElement(<MigrationFlow packages={response}
                                    couponExists={sessionStorage.getItem("discount-coupon")}
                                    promoTargetDisplayName={promoTargetDisplayName}
                                    promoRedirectURL={promoRedirectURL}
                                    contestSession={!!contestSession}
                                    flow={"unknown"}
                                    handleNextStep={handleNextStep}
                                    token={token}
      />)
    } else if (flow && flow === "newsletter") {
      //flow newsletter
      const response = await ProductsService.getProductReferenceStandalone({
        productId: !!authData.productId
            ? authData.productId
            : -1,
        pricePoint: !!authData.pricePoint ? authData.pricePoint : undefined,
        flow: "newsletter"
      })

      setFlowElement(<MigrationFlow packages={response.data}
                                    couponExists={sessionStorage.getItem("discount-coupon")}
                                    promoTargetDisplayName={promoTargetDisplayName}
                                    promoRedirectURL={promoRedirectURL}
                                    contestSession={!!contestSession}
                                    flow={"newsletter_save_desk"}
                                    handleNextStep={handleNextStep}
      />)
    } else if (!!authData.subscriptionId || (!(!!UserService.isSubscriptionAction()) && !!sessionStorage.getItem("discount-coupon"))) {
      //flow already has a subscription or has coupon upon registration

      if(!!couponInfo) { //coupon exists
        setFlowElement(<MigrationFlow packages={couponInfo}
                                      couponExists={sessionStorage.getItem("discount-coupon")}
                                      flow={"migrate"}
                                      handleNextStep={handleNextStep} />)
      }else { //coupon dosent exist
        if (account.migrateFromCancellation) { //unsubscribe flow
          const response = await ProductsService.getCancellationProductReference({
            productId: authData.productId,
            pricePoint: authData.pricePointHandle,
          })

          setFlowElement(<MigrationFlow packages={response.data}
                                        couponExists={sessionStorage.getItem("discount-coupon")}
                                        promoTargetDisplayName={promoTargetDisplayName}
                                        promoRedirectURL={promoRedirectURL}
                                        contestSession={!!contestSession}
                                        flow={"save_desk"}
                                        handleNextStep={handleNextStep}
                                        cancellationFlow={true}
          />)
        } else {
          const response = await ProductsService.getProductReferenceStandalone({
            productId: !!authData.productId
                ? authData.productId
                : -1,
            pricePoint: !!authData.pricePoint ? authData.pricePoint : undefined,
          })

          setFlowElement(<MigrationFlow packages={response.data}
                                        couponExists={sessionStorage.getItem("discount-coupon")}
                                        promoTargetDisplayName={promoTargetDisplayName}
                                        promoRedirectURL={promoRedirectURL}
                                        flow={"migrate"}
                                        contestSession={!!contestSession}
                                        handleNextStep={handleNextStep}
          />)
        }
      }
    } else {
      //flow just registered or isnt initialized yet
      try {
        if(!!couponInfo) {
          setFlowElement(<RegistrationFlow
              packages={couponInfo} couponExists={sessionStorage.getItem("discount-coupon")}
              promoTargetDisplayName={promoTargetDisplayName} contestSession={!!contestSession}
              handleNextStep={handleNextStep}
          />)
        }else {
          const response = await ProductsService.getProductsStandalone();
          setFlowElement(<RegistrationFlow
              packages={response.data.packages} couponExists={false}
              promoTargetDisplayName={promoTargetDisplayName} contestSession={!!contestSession}
              handleNextStep={handleNextStep}
          />)
        }
        return true;
      }catch (error){
        //TODO handle error
      }
    }
  }

  useEffect(() => {
    if(!(isLoading("INIT"))){
      addLoading("INIT")
    }
    if(loggedIn){
      checkSubscriptionAction().then(response => {
        if(response){
          checkFlow().then(secondResponse =>{
            removeLoading("INIT");
          })
        }
      })
    }
  }, [loggedIn]); //eslint-disable-line react-hooks/exhaustive-deps

  return (<>
          {isLoading() ? (<Loader />) : (
              <>
                {FlowElement}
                <PopUp
                    openPopUp={verificationSent}
                    setOpenPopUp={setVerificationSent}
                    maxWidth="md"
                    showCloseButton={false}
                    outlined={false}
                    error={false}
                    closeWhenClickedOutside={false}
                >
                    <GenericError
                        setClose={setVerificationSent}
                        success={true}
                        mainText={"Επιβεβαίωση email"}
                        buttonText={"Συνέχεια"}
                        showIcon={false}

                        // executeFunction={() => navigate("/Account")}
                    >
                        <div className={"verify-email-popup-secondary-wrapper"}>
                            <span className={"verify-email-popup-secondary-first"} >Σύντομα θα λάβεις ένα μήνυμα στο email σου. Παρακαλούμε ακολούθησε τις οδηγίες που αναγράφονται σε αυτό, προκειμένου να επιβεβαιώσεις την διεύθυνση email που μας έχεις δηλώσει.</span>
                            <span className={"verify-email-popup-secondary-sub"} >Παρακαλούμε ελέγξτε και τον φάκελο με τα ανεπιθύμητα.</span>
                        </div>
                    </GenericError>
                </PopUp>
              </>)}
          {!!phoneNumberVerifyToken &&
              <PhoneVerification phoneNumberVerifyToken={phoneNumberVerifyToken}
                                 triggerNextPopup={setVerificationSent.bind(null, true)}
                                 finallyAction={()=>{
                                     removeLoading("INIT");
                                 }}
              />
          }
      </>);
}
export default PickProgram;
