import React, { useState } from "react";
import "./ImageSlider.css";
import CmsHelper from "../../../Utilities/CmsHelpers";

function ImageSlider({ images }) {
  let slides = [];

  images.forEach((img, index) => {
    slides.push({ src: CmsHelper.getImagePath(img) })
  });

  const [activeIndex, setActiveIndex] = useState(0);

  const changeImage = (index) => {
    setActiveIndex(index);
  };
  return (
    <div className="ant-image-slider">
      {slides.map((slide, index) => (
        <div
          className={`${index === activeIndex ? "active" : ""}`}
          style={{
            left: `${(index+1) * -20}px`,
            zIndex: `${index === activeIndex ? slides.length : slides.length-index}`,
            backgroundImage: ` ${index !== activeIndex
              ? "linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),"
              : ""
              } url("${slide.src}")`,
          }}
          key={index}
          onClick={() => changeImage(index)}
        ></div>
        // <img src={slide.src} alt={`slide-image ${index}`} key={index} />
      ))}
    </div>
  );
}

export default ImageSlider;
