import ProductCard from "./ProductCard/ProductCard";
import "./ProductCards.css"
import {LandingPageHelper} from "../../Utilities/CmsHelpers";
import {useCms} from "../../../context/CmsContext";
import React, {useState} from "react";

function ProductCards({products: receivedProducted}){
    const {landingData} = useCms();
    const productData = LandingPageHelper.getProductSection(landingData);

    const orderMap = productData.landingPrograms.reduce((map, item, index) => { //creates an order map to be used to sort compatibility matrix array
        map[item.displayNam.data.attributes.value] = index;
        return map;
    }, {});

    const [products] = useState(receivedProducted
        .filter(product => productData.landingPrograms.some(cmsProduct => cmsProduct.displayNam.data.attributes.value === product.displayName))
        // .filter(product => product.displayName !== "Lite")
        .sort((a, b) => {
            return orderMap[a.displayName] - orderMap[b.displayName];
        }))

    // we use this to show content that relates to promoting migration from ANT1 FIFA to Formula1
    const fifaShadowOffer =
        products.length === 1 &&
        ["F1shadow", "F1offer9", "F1offer20"].includes(products[0].displayName);

    const productDisclaimers = LandingPageHelper.getProductDisclaimersSection(
        landingData
    );
    const disclaimersToShow = productDisclaimers.filter((item) =>
        products.find(
            (product) =>
                product.displayName === item.displ_na.data.attributes.value
        )
    );

    return (
        <div className={"products-wrapper"}>
            <span className={"products-title"}>{
                LandingPageHelper.getProductTtitle(productData, fifaShadowOffer)
                    .header
            }</span>
            <div className={"product-cards-wrapper-outer"}>
                <div
                    className={`product-cards-wrapper ${products.some(product => product.displayName === "Lite") ? "" : "noVertical"}`}>
                    {products.map((product, index) => {
                            return (<ProductCard key={index}
                                                 product={product}
                                                 productData={LandingPageHelper.getProgram(productData.landingPrograms, product.displayName)}
                                                 vertical={product.displayName === "Lite"}/>)
                        })}
                </div>
                <div className={"product-disclaimers"}>
                    {disclaimersToShow.map((d) => {
                        return d.value.map((item, idx) => {
                            return <p key={idx}>{item.value}</p>;
                        });
                    })}
                </div>
            </div>
        </div>
    )
}

export default ProductCards;