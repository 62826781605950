import React from "react";
import "./Loader.css"

function Loader({open, isModal = false}) {
  return (
    <div className={`loader-container ${isModal ? "modal": ""}`}>
        <div className="spinner"></div>
    </div>
  );
}

export default Loader;
