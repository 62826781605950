import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./components/App/App";
import reportWebVitals from "./reportWebVitals";
import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import HttpApi from "i18next-http-backend";
import {QueryClient, QueryClientProvider} from "react-query";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init(
    i18n
      .use(initReactI18next) // passes i18n down to react-i18next
      .use(HttpApi)
      .init({
        lng: "gr",
        supportedLngs: ["en", "gr"],
        fallbackLng: "gr",

        interpolation: {
          escapeValue: false,
        },
        backend: {
          loadPath: "/locales/{{lng}}/translation.json",
        },
        react: {
          useSuspense: false,
        },
      })
  );

const queryClient = new QueryClient();

// Google Tag Manager
if(process.env.REACT_APP_TAG_ID){
    const noscript = document.createElement('noscript')
    const iframe = document.createElement('iframe')
    iframe.height = '0';iframe.width = '0';iframe.style.display = 'none';iframe.style.visibility = 'hidden';

    const script = document.createElement('script')
    script.textContent = `
    (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({
        'gtm.start':
                new Date().getTime(), event: 'gtm.js'
        });
        var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src =
              'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', '${process.env.REACT_APP_TAG_ID}');`

    iframe.src = `https://www.googletagmanager.com/ns.html?id=${process.env.REACT_APP_TAG_ID}`

    document.head.appendChild(script);
    noscript.appendChild(iframe);
    document.body.insertBefore(noscript, document.body.firstChild);
}
//  End Google Tag Manager

// Start Google Analytics 4 (GA4)
const script2 = document.createElement('script')
script2.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GA4_ID}`
document.head.appendChild(script2);

function gtag() {
    window.dataLayer.push(arguments);
}

gtag("js", new Date());
gtag("config", `${process.env.REACT_APP_GA4_ID}`);
// End Google Analytics 4 (GA4)

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <QueryClientProvider client={queryClient}>
    <App />
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
