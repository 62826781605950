import CmsHelper, {ProgramBannersHelper} from "../../Utilities/CmsHelpers";
import React from "react";
import {useAccount} from "../../../context/AccountContext";

export default function Banner({ bannerData, deviceType, update, displayName }){
    let account = useAccount();
    const type = account.migrateFromCancellation
        ? CmsHelper.CANCELLATION_MIGRATE
        : (update ? CmsHelper.UPGRADE_PROGRAM : CmsHelper.REGISTRATION)
    const banner = ProgramBannersHelper.getBanner(bannerData, type, deviceType, displayName)
        ?? ProgramBannersHelper.getGenericBanner(bannerData, type, deviceType);
    return (banner?.banner &&
        <img
            src={CmsHelper.getImagePath(banner.banner)}
            alt="product banner"
            id={deviceType.toLowerCase()}
            style={{width: '100%', paddingBottom: '24px'}}
        /> );
}