import { Link } from "@mui/material";
import React, { useState } from "react";
import "./Footer.css";
import { useTranslation } from "react-i18next";
import PopUp from "../Utilities/PopUp";
import TermsAndConditions from "../TermsAndConditions/TermsAndConditions";

function Footer() {
  const { t } = useTranslation();

  const [termsPopUp, setTermsPopUp] = useState(false);

  const handleTermsAndConditions = (event) => {
    event.preventDefault();
    setTermsPopUp(true);
  };

  return (
    <footer className="page-footer">
      <div className="l1">
        <div className="l2">
          <Link href="#" underline="none" target="_blank">
            {t("footerLink1")}
          </Link>

          <Link
            href="https://watch.antennaplus.gr/#/embed/about-guest?deferLogin=1"
            underline="none"
            target="_blank">
            {t("footerLink2")}
          </Link>

          <Link onClick={()=>window.__tcfapi('displayConsentUi', 2, function () {
          })} underline="none" target="_blank">
            {t("footerLink4")}
          </Link>
        </div>
        <div className="l2">
          <Link
            href="#"
            underline="none"
            onClick={handleTermsAndConditions}>
            {t("footerLink5")}
          </Link>

          <Link
            href="https://watch.antennaplus.gr/#/embed/contact-guest?deferLogin=1"
            underline="none"
            target="_blank">
            {t("footerLink6")}
          </Link>

          <Link
            href="https://help.antennaplus.gr/"
            underline="none"
            target="_blank">
            {t("footerLink7")}
          </Link>
        </div>
      </div>

      <div className="social-wrapper">
        <span>{t("footerFollowUs")}</span>
        <div className={"social-icons"}>
          <Link
            href="https://www.facebook.com/ANT1plus/"
            underline="none"
            target="_blank">
            <img src="images/footer/facebook.svg" alt="Facebook icon"/>
          </Link>
          <Link
            href="https://twitter.com/Ant1_plus"
            underline="none"
            target="_blank">
            <img src="images/footer/twitter.svg" alt="Twitter icon"/>
          </Link>
          <Link
            href="https://www.instagram.com/ant1plus_official/"
            underline="none"
            target="_blank">
            <img src="images/footer/instagram.svg" alt="Instagram icon"/>
          </Link>
          <Link
            href="https://www.youtube.com/channel/UCGCzXaMVL_GQFZJFVOZ_Ppg"
            underline="none"
            target="_blank">
            <img src="images/footer/youtube.svg" alt="Youtube icon"/>
          </Link>
          <Link
              href="https://www.tiktok.com/@ant1plus"
              underline="none"
              target="_blank">
            <img src="images/footer/tiktok.svg" alt="Tiktok icon"/>
          </Link>
        </div>
      </div>

      <PopUp
        openPopUp={termsPopUp}
        setOpenPopUp={setTermsPopUp}
        maxWidth="md"
        showCloseButton={true}
        title={"Όροι και προϋποθέσεις"}>
        <TermsAndConditions />
      </PopUp>
    </footer>
  );
}

export default Footer;
